// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import baseUserContextComponent from "@/components/baseUserContextComponent";
import { Component } from "vue-property-decorator";

@Component({})
export default class homePage extends baseUserContextComponent {
	title: string = "Benvenuto nell'";
	subtitle: string = "Area Amministrativa";

	created() {

	}
}
